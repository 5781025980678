import { Component, Input, Inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ExternalSearchComponent } from '@src/base-components/external-search/external-search.component';
import { ExternalService } from '@src/services/external/external.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { ConfigService } from '@src/services/layout/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({ 
    selector: 'app-external-search-after-sale-frederique',
    templateUrl: '../../../base-components/external-search/external-search.component.html',
    styleUrls: ['../../../base-components/external-search/external-search.component.scss']
})

export class ExternalSearchFrederiqueComponent extends ExternalSearchComponent implements AfterViewInit {

    @ViewChild('logo') logo: ElementRef;

    constructor(
        @Inject('AuthService')
        authService : IAuthService,
        externalService: ExternalService,
        configService: ConfigService,
        translateService: TranslateService) {
        super(authService, externalService, configService, translateService);

        this.srcLogoClient = 'assets/frederique-icon.png';
        configService.setIsPublicPage(true);
    }

    ngAfterViewInit() {
        this.logo.nativeElement.setAttribute('style', 'margin:20px;');
    }
}
