import { Component, Inject } from '@angular/core';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { SaleDetailsProductComponent } from '@src/base-components/sales/sale-details/product/sale-details-product.component';
import { IAuthService } from '@src/auth/auth.service.interface';

@Component({
    selector: 'app-sale-details-product',
    templateUrl: '../../../../../base-components/sales/sale-details/product/sale-details-product.component.html',
    styleUrls: ['../../../../../base-components/sales/sale-details/product/sale-details-product.component.scss']
})

export class SaleDetailsProductFrederiqueComponent extends SaleDetailsProductComponent {

    constructor(warrantiesService: WarrantiesService,
        @Inject('AuthService')
        authService: IAuthService) {
        super(warrantiesService, authService);
    }
}