import { EnvironmentConfig } from '@src/environments/EnvironmentConfig';
import { EnvironmentNames } from '@src/environments/environments.name';
import { AuthService } from '@src/auth/auth.service';

export const environment: EnvironmentConfig = {
  authServiceType: AuthService,
  clientName: 'frederique',
  envName: EnvironmentNames.qa,
  siteKey:'6LcB-qYUAAAAAJ_FmosZ8ZP6J6vo2vNmfFfx3nIG',
  authConfig: {
    // Url of the Identity Provider
    issuer: 'https://auth.qa.ewarranty.frederiqueconstant.com',
    loginUrl: 'https://auth.qa.ewarranty.frederiqueconstant.com/connect/authorize',
    postLogoutRedirectUri: 'https://qa.ewarranty.frederiqueconstant.com',
    // URL of the SPA to redirect the user to after login
    redirectUri: 'https://qa.ewarranty.frederiqueconstant.com/login',
    silentRefreshRedirectUri: 'https://qa.ewarranty.frederiqueconstant.com/silent-refresh.html',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: 'WAS_SPA',
    responseType: 'token',
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile was roles email',
    oidc: true,
    showDebugInformation: false
  },
  apiBaseUrl: 'https://api.qa.ewarranty.frederiqueconstant.com',
  mapBox: {
    appKey: 'pk.eyJ1IjoiZGVubmlzY2FycmVpcmEiLCJhIjoiY2plemh5bWlqMGJyajMzcGRrODJ0MTlkZCJ9.jaEGWDdTSALHzEoJsMszyQ'
  }
};


