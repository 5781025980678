import { Component, Input, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { Warranty } from '@src/model/warranty/warranty';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { UserRolesEnum } from '@src/model/userRoles';
import { WarrantiesService } from '@src/services/warranty/warranties.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { User } from '@src/model/user';
import { WarrantyOperation } from '@src/model/warranty/warrantyOperation';
import { NgxDatatableBase } from '@src/shared/tables/ngx-datatable-base';
import { TranslateService } from '@ngx-translate/core';
import { RepairsService } from '@src/services/repairs/repairs.service';
import { RepairWarranty } from '@src/model/repairWarranty';
import { Retailer } from '@src/model/retailer';
import { WarrantyExtension } from '@src/model/warranty/warrantyExtension';
import { WarrantyExtensionType } from '@src/model/Enums/warrantyExtensionType';
import { SaleDetailsWarrantiesBaseComponent } from '@src/base-components/sales/sale-details/warranties/sale-details-warranties.component';

@Component({
    selector: 'app-sale-details-warranties',
    templateUrl: '../../../../../base-components/sales/sale-details/warranties/sale-details-warranties.component.html'
})

export class SaleDetailsWarrantiesFrederiqueComponent extends SaleDetailsWarrantiesBaseComponent{

    constructor( @Inject('AuthService')
    authService: IAuthService,
    retailersService: RetailersService,
    warrantiesService: WarrantiesService,
    repairWarrantiesService: RepairsService,
    translateService: TranslateService) {
    super(authService, retailersService,warrantiesService,repairWarrantiesService,translateService);
    }
}

