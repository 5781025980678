import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Retailer } from '@src/model/retailer';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { Channel } from '@src/model/channel';
import { RetailerDetailsInfoComponent } from '@src/base-components/retailers/retailer-details/retailer-details-info/retailer-details-info.component';
import { PosDetails } from '@src/model/posDetails';

@Component({
    selector: 'app-retailer-details-info',
    templateUrl: './retailer-details-info.component.html',
    styleUrls: ['./retailer-details-info.component.scss']
})

export class RetailerDetailsInfoFrederiqueComponent extends RetailerDetailsInfoComponent implements OnInit {
    @Input() editEnabled = false;
    @Input() retailer: Retailer = new Retailer();
    @Input() posCluster = [];
    @Input() posStatus = [];
    @Input() posReason = [];
    @Output() updateRetailer = new EventEmitter<Retailer>();
    @Output() updateEdition = new EventEmitter<boolean>();

    showPassword: boolean;
    inEdition: boolean;
    channel1: Channel;
    channel2: Channel;
    channel3: Channel;
    posDetails : PosDetails;   


    constructor(private retailersService: RetailersService) {
        super();       
    }

    ngOnInit() {
        if (!this.retailer.channelId) {
            return;
        }

        this.retailersService.getChannelsByRetailerId(this.retailer.id).subscribe(channels => {
           this.setChannelsRetailer(channels);
        });
        
        // this.formattedClosingDate = this.retailer.posClosingDate.getFullYear() + '/' + this.retailer.posClosingDate.getMonth() + '/' + this.retailer.posClosingDate.getDate();
        // this.formattedOpeningDate = this.retailer.posClosingDate.getFullYear() + '/' + this.retailer.posClosingDate.getMonth() + '/' + this.retailer.posClosingDate.getDate();
    }

    setChannelsRetailer(channels: Channel[]) {
        if (channels == undefined || channels.length === 0) {
            return;
        }

        this.channel1 = channels.find(ch => ch.id == this.retailer.channelId);

        if (this.channel1) {
            this.channel2 = channels.find(ch => ch.id == this.channel1.parentChannelId);
        }
    }    
}