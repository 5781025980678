import { Component } from '@angular/core';
import { TermsBaseComponent } from '@src/base-components/terms/terms-base.component';
import { ConfigService } from '@src/services/layout/config.service';

@Component({
    selector: 'app-page-terms',
    templateUrl: '../../../base-components/terms/terms-base.component.html',
    styleUrls: ['../../../base-components/terms/terms-base.component.scss']
})
export class TermsComponent extends  TermsBaseComponent{
    constructor(configService: ConfigService) {
        const params = {
            appName: 'eWarranty',
            companyName: 'Frederique Constant',
            products: 'watches',
            logo : 'assets/frederique-icon.png',
            ContactEmail :'was.support@frederique.com'
        }
        const logoUrl = params.logo;
        super(params, logoUrl, configService);
    }
}