import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { DashboardDefaultNewFilterComponent } from './new-filter/dashboard-default-new-filter.component';
import { DashboardDefaultFilterComponent } from './filter/dashboard-default-filter.component';
import { MetricWithLocation } from '@src/model/metricWithLocation';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { DashboardSalesChartComponent } from '../../charts/sales-chart/dashboard-sales-chart.component';

@Component({
  selector: 'app-dashboard-default',
  templateUrl: './dashboard-default.component.html',
  styleUrls: ['./dashboard-default.component.scss']
})

export class DashboardDefaultComponent implements OnInit {

  topRetailerSales: { name: string, value: number }[] = [];
  totalActivations: { name: string, value: number }[] = [];
  topRetailers = 10;
  retailersSales: MetricWithLocation[] = [];
  from: Date;
  to: Date;
  interval: string;
  retailerId?: number;
  marketId?: number;
  entryName: string;
  entryType?: string;
  entryId?: number;
  clearEntriesFlag:boolean;
  warningActivations: boolean;
  loggedUser: UserProfile;
  retailersId: any[];
  isLoading = false;
  displayMap = false;
  canExportSales = false;
  hasNewFilter: boolean;
  isLoadingExport = false;

  @ViewChild(DashboardDefaultNewFilterComponent) dashboardDefaultNewFilterComponent: DashboardDefaultNewFilterComponent;
  @ViewChild(DashboardDefaultFilterComponent) dashboardDefaultFilterComponent: DashboardDefaultFilterComponent;
  @ViewChild(DashboardSalesChartComponent) dashboardSalesChartComponent: DashboardSalesChartComponent;

  constructor(
    private dashboardService: DashboardService,
    private configService: ConfigService) {
      this.hasNewFilter = this.configService.hasNewFilter().dashboard;
    }

  ngOnInit() {
    this.dashboardDefaultNewFilterComponent.selectedTimeRange = 'last-month';
    this.dashboardDefaultFilterComponent.selectedTimeRange = 'last-month';

    const dateStr = this.hasNewFilter ? this.dashboardDefaultNewFilterComponent.computeTimeRange('last-month') :
              this.dashboardDefaultFilterComponent.computeTimeRange('last-month');
    this.from = dateStr.from;
    this.to = dateStr.to;
    this.interval = dateStr.interval;

    this.isLoading = true;
    this.getSalesRetailerMetrics();
    this.getTotalActivations();
  }

  getSalesRetailerMetrics() {

    let request  = this.entryType === 'f' ?
    {
      marketId: this.marketId,
      retailerId: this.retailerId,
      to: this.to,
      from: this.from,
      familyId: this.entryId }
    : {
      marketId: this.marketId,
      retailerId: this.retailerId,
      to: this.to,
      from: this.from,
      productId: this.entryId
    };
    this.isLoading = true;
    this.dashboardService.getRetailersMetrics(request).subscribe(data => {
        this.topRetailerSales = data.sort((a, b) => b.value - a.value).slice(0, this.topRetailers).map(m => {
          return { name: m.label, value: m.value };
        });
        this.retailersSales = data;
      });
  }

  getTotalActivations() {

    let request  = this.entryType === 'f' ?
    {
      marketId: this.marketId,
      retailerId: this.retailerId,
      to: this.to,
      from: this.from,
      familyId:this.entryId }
    : {
      marketId: this.marketId,
      retailerId: this.retailerId,
      to: this.to,
      from: this.from,
      productId:this.entryId
    };

    this.dashboardService.getSalesMetricsByTypes(request).subscribe(data => {
        this.totalActivations = data.sort((a, b) => b.value - a.value).map(m => {
          if ((m.label === 'Duplication' || m.label === 'Fake') && m.value > 0) {
            this.warningActivations = true;
          } else {
            this.warningActivations = false;
          }
          return { name: m.label, value: m.value };
        });
        this.isLoading = false;
      });
  }


  changeRangeTime({ from, to, interval }: { from: Date, to: Date, interval: string }) {
    this.from = from;
    this.to = to;
    this.interval = interval;
    this.isLoading = true;
    this.getSalesRetailerMetrics();
    this.getTotalActivations();
  }
  changeLocation({ marketId, retailerId }: { marketId?: number, retailerId?: number }) {
    this.marketId =  marketId;
    this.retailerId = retailerId;
    this.isLoading = true;
    this.getSalesRetailerMetrics();
    this.getTotalActivations();
  }

  changeProductInfo({ entryName, entryType, entryId, clearEntriesFlag}
    : { entryName: string, entryType?: string, entryId?: number, clearEntriesFlag:boolean }){
    this.entryName = entryName;
    this.entryType = entryType;
    this.entryId = entryId;
    this.clearEntriesFlag = clearEntriesFlag;
    this.isLoading = true;
    this.getSalesRetailerMetrics();
    this.getTotalActivations();
  }

  clearFilter({ from, to, interval,entryName, entryType, entryId, clearEntriesFlag,marketId, retailerId }
    :{ from: Date, to: Date, interval: string ,entryName?: string, entryType?: string, entryId?: number, clearEntriesFlag:boolean, marketId?: number, retailerId?: number}){
      this.from = from;
      this.to = to;
      this.interval = interval;
      this.entryName = entryName;
      this.entryType = 'total';
      this.entryId = entryId;
      this.clearEntriesFlag = clearEntriesFlag;
      this.retailerId = retailerId;
      this.marketId =  marketId;
      this.isLoading = true;
      this.getSalesRetailerMetrics();
      this.getTotalActivations();
  }

  handleMap(event) {
    if (event === true) {
      this.getSalesRetailerMetrics();
      this.isLoading = false;
    }
    this.displayMap = event;
  }

  handleExportSales(event) {
    if (event === true) {
      this.dashboardSalesChartComponent.canExportSales = event;
    }
  }

  loadingExportSales(event) {
    this.isLoadingExport = event;
  }
}