import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import * as moment from 'moment';
import { SharedDataService } from '@src/services/sharedData.service';
import { Observable } from 'rxjs';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { ProductsService } from '@src/services/products/products.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { DashboardService } from '@src/services/dashboard/dashboard.service';
import { SalesService } from '@src/services/sales/sales.service';
import { SalesParameters } from '@src/model/filter-parameters/sales-parameters';
import { ExportSalesParameters } from '@src/model/filter-parameters/export-sales-parameters';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '@src/services/notifications/notifications.service';
import { UsersService } from '@src/services/users/users.service';
import { UserNotification } from '@src/model/notification';
import { environment } from '@src/environments/environment';
import { UserRolesEnum } from '@src/model/userRoles';

@Component({
  selector: 'app-sales-filter',
  templateUrl: './sales-filter.component.html',
  styleUrls: ['./sales-filter.component.scss']
})

export class SalesFilterComponent implements OnInit {
  selectedTimeRange: string;
  timeRangeList: string[];
  inputSNWCQuery: string;
  inputLocationQuery: string;
  inputProductQuery: string;
  customFrom?: Date;
  customTo?: Date;
  loggedUser: UserProfile;
  layoutDirection: string;
  filterParameters: SalesParameters;
  isPrepairedClient: boolean;
  selectedProductId: number;
  checkedActivation: boolean;
  checkedReturn: boolean;
  checkedCorrection: boolean;
  checkedFake: boolean;
  checked2DCyes: boolean;
  checked2DCno: boolean;
  checkedDuplication: boolean;
  isLoading = false;
  hasCorrection: boolean;
  isOpen = true;
  validUser: boolean;
  showD2CExtension = false;

  @Output() changeFilter = new EventEmitter<SalesParameters>();
  @Output() clearFilter = new EventEmitter<SalesParameters>();
  @Input() newfilterParameters: SalesParameters;

  constructor(
    @Inject('AuthService') private authService: IAuthService,
    private retailersService: RetailersService,
    private productsService: ProductsService,
    private configService: ConfigService,
    private dashboardService: DashboardService,
    private salesService: SalesService,
    private translateService: TranslateService,
    private toastrManager: ToastrService,
    private notifcationService: NotificationsService,
    private usersService: UsersService,
    private sharedDataService: SharedDataService) {
    this.layoutDirection = this.configService.getLayoutOrientation();

    // TODO: persit the configService.templateConf because on heritance of components the values does not change.

    this.isPrepairedClient = this.configService.isPrepairedClient();
    this.hasCorrection = this.configService.hasCorrection();
    this.authService.getCurrentUser().subscribe(u => {
      this.loggedUser = u;
    });

    // const isBreitlingClient = environment.clientName && environment.clientName === 'breitling';
    // const isZenithClient = environment.clientName && environment.clientName === 'zenith';

    // if (isBreitlingClient || isZenithClient) {
    //   this.isPrepairedClient = true;
    //   this.hasCorrection = false;
    // }

    this.hasCorrection = false;

    this.showD2CExtension = this.showD2CExtensionData();
  }

  ngOnInit() {
    this.selectedTimeRange = 'last-month';
    this.timeRangeList = [
      'since-begining', 'last-5-years', 'last-year', 'last-month', 'last-week', 'today', 'custom'
    ];

    const intervalDates = this.computeTimeRange(this.selectedTimeRange);
    this.filterParameters = new SalesParameters();
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.filterParameters.selectedTimeRange = 'last-month';
    
    if (this.newfilterParameters !== undefined) {
      this.filterParameters = this.newfilterParameters;
      if (this.newfilterParameters.operationsType.includes(1)) {
        this.checkedActivation = true;
      }
      if (this.newfilterParameters.operationsType.includes(2)) {
        this.checkedReturn = true;
      }
      if (this.newfilterParameters.operationsType.includes(3)) {
        this.checkedCorrection = true;
      }
      this.selectedTimeRange = this.newfilterParameters.selectedTimeRange == undefined ? 'last-month' : this.newfilterParameters.selectedTimeRange;
      if (this.newfilterParameters.selectedTimeRange == 'custom') {
        this.customTo = this.newfilterParameters.to;
        this.customFrom = this.newfilterParameters.from;
      }
      this.inputSNWCQuery = this.newfilterParameters.serialNumber;
      this.inputProductQuery = this.newfilterParameters.productSku;
    }
    this.changeFilter.emit(this.filterParameters);
    this.validateUserExportButton();
  }

  clear() {
    this.selectedTimeRange = 'last-month';
    this.inputLocationQuery = '';
    this.inputProductQuery = '';
    this.inputSNWCQuery = '';
    this.checkedActivation = false;
    this.checkedReturn = false;
    this.checkedCorrection = false;
    this.checkedFake = false;
    this.checked2DCyes = false;
    this.checked2DCno = false;
    this.checkedDuplication = false;

    const intervalDates = this.computeTimeRange(this.selectedTimeRange);
    this.filterParameters = new SalesParameters();
    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.clearFilter.emit(this.filterParameters);
  }

  changeTimeRange(event) {
    this.filterParameters.selectedTimeRange = this.selectedTimeRange;
    if (this.selectedTimeRange !== 'custom') {
      const intervalDates = this.computeTimeRange(this.selectedTimeRange);
      this.filterParameters.from = intervalDates.from;
      this.filterParameters.to = intervalDates.to;
      this.changeFilter.emit(this.filterParameters);
    }
  }
  selectCustomFrom(date: any) {
    const intervalDates = this.computeInterval(this.customFrom, this.customTo);

    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.changeFilter.emit(this.filterParameters);
  }
  selectCustomTo(date: any) {
    const intervalDates = this.computeInterval(this.customFrom, this.customTo);

    this.filterParameters.from = intervalDates.from;
    this.filterParameters.to = intervalDates.to;
    this.changeFilter.emit(this.filterParameters);
  }

  changeLocation(target: any) {
    const entryId = target.item.id;
    const entryType = target.item.type;
    this.inputLocationQuery = target.item.name;

    if (entryType === 'r') {
      this.filterParameters.retailerId = entryId;
    } else if (entryType === 'm') {
      this.filterParameters.marketId = entryId;
    } else {
      this.filterParameters.businessGroup = this.inputLocationQuery;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  changeSNWC(target: any) {
    const entryType = target.item.type;
    this.inputSNWCQuery = target.item.name;

    if (entryType === 's') {
      this.filterParameters.serialNumber = this.inputSNWCQuery;
    } else {
      this.filterParameters.warrantyCard = this.inputSNWCQuery;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  selectProductEntry(target: any) {
    this.selectedProductId = target.item.id;
    this.inputProductQuery = target.item.name;

    this.productsService.getProductById(this.selectedProductId).subscribe(product => {
      if (product) {
        this.filterParameters.productSku = product.sku;
        this.changeFilter.emit(this.filterParameters);
      }
    });
  }

  // formatter = (x: { name: string }) => x.name;
  formatterSNWC = (x: { name: string }) => {
    if (this.inputSNWCQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputSNWCQuery };
    }
    return x.name;
  };
  formatterMarketRetailer = (x: { name: string }) => {
    if (this.inputLocationQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputLocationQuery };
    }
    return x.name;
  };
  formatterProduct = (x: { name: string }) => {
    if (this.inputProductQuery == "") {
      x = { name: undefined };
    } else {
      x = { name: this.inputProductQuery };
    }
    return x.name;
  };
  searchLocationEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'name', 'marketname', 'business')
      )

  searchSNWCEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.salesService.getSerialNumbersWarrantyCardsNames(term)
      )

  searchProductEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.productsService.getProductsNames(term, false)
      )

  public computeTimeRange(timeRange: string): { from: Date, to: Date } {
    const now = new Date();
    switch (timeRange) {
      case 'last-month': {
        return { from: moment().add(-1, 'M').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-week': {
        return { from: moment().add(-1, 'w').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-year': {
        return { from: moment().add(-1, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'last-5-years': {
        return { from: moment().add(-5, 'y').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      case 'today': {
        return { from: moment().add(0, 'd').toDate(), to: moment(now).add(1, 'd').toDate() };
      }
      default: {
        return { from: null, to: null };
      }
    }
  }

  computeInterval(from = null, to = null): { from: Date, to: Date } {
    let _to = to != null ? moment(new Date(to.year, to.month - 1, to.day)) : moment();
    const _from = from != null ? moment(new Date(from.year, from.month - 1, from.day)) : moment().add(-3, 'y');

    if (moment(_to.toDate()).isSameOrAfter(_from.toDate())) {
      _to = moment(_to).add(1, 'd');
    }

    return { to: _to.toDate(), from: _from.toDate() };
  }

  toggleOperationType(operationType: string) {
    switch (operationType) {
      case 'activation':
        this.addOrRemoveOperationsType(1);
        break;
      case 'return':
        this.addOrRemoveOperationsType(2);
        break;
      case 'correction':
        this.addOrRemoveOperationsType(3);
        break;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  addOrRemoveOperationsType(operationType: number) {
    if (this.filterParameters.operationsType.find(o => o === operationType)) {
      this.filterParameters.operationsType = this.filterParameters.operationsType.filter(o => o !== operationType);
    } else {
      this.filterParameters.operationsType.push(operationType);
    }
  }

  filterFake(event) {
    this.filterParameters.isFake = event.target.checked;
    this.changeFilter.emit(this.filterParameters);
  }

  filterD2C() {
    if ((this.checked2DCyes && this.checked2DCno) || (!this.checked2DCyes && !this.checked2DCno)) {
      this.filterParameters.hasD2CExtension = null;
    }
    else {
      this.filterParameters.hasD2CExtension = this.checked2DCyes || !this.checked2DCno;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  filterDuplication(event) {
    this.filterParameters.isDuplicate = event.target.checked;
    this.changeFilter.emit(this.filterParameters);
  }

  exportSales(event) {
    let intervalDates;
    if (this.selectedTimeRange !== 'custom') {
      intervalDates = this.computeTimeRange(this.selectedTimeRange);
    } else {
      intervalDates = this.computeInterval(this.customFrom, this.customTo);
    }

    if (event.detail > 1) {
      return;
    }
    this.isLoading = true;
    this.isOpen = true;
    const request = new ExportSalesParameters();
    request.productIds = this.selectedProductId !== undefined ? [this.selectedProductId] : [];
    request.from = intervalDates.from;
    request.to = intervalDates.to;
    request.retailerId = this.filterParameters.retailerId;
    request.marketId = this.filterParameters.marketId;
    request.operationsType = this.filterParameters.operationsType;
    request.isDuplicate = this.filterParameters.isDuplicate;
    request.isFake = this.filterParameters.isFake;
    request.serialNumber = this.filterParameters.serialNumber;
    request.warrantyCard = this.filterParameters.warrantyCard;

    const userNotification = new UserNotification();
    userNotification.descriptionType = 2;
    userNotification.isDeleted = false;
    userNotification.isRead = false;
    userNotification.notificationType = 2;
    userNotification.sourcePageType = 1;

    this.usersService.getMyUser().subscribe(data => {
      userNotification.userId = data.id;
      this.notifcationService.createNotification(userNotification).subscribe(data => {
        this.isOpen = false;
        request.parentNotificationId = data.id;
        this.dashboardService.exportSales(request).subscribe(data => {
          this.isLoading = false;
        });
        setTimeout(() => {
          this.sharedDataService.changeMessage("notification:created");
        }, 60000);
      });
    })
  }

  protected validateUserExportButton() {
    if ((this.loggedUser.role[0] === UserRolesEnum.REPAIR)) {
      this.validUser = false;
    }
    else {
      this.validUser = true;
    }
  }

  hideExportButton(): boolean {
    return this.validUser;
  }

  private showD2CExtensionData() {
    return environment.clientName && environment.clientName === 'tag';
  }
}
