import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProductsService } from '@src/services/products/products.service';
import { ProductItem } from '@src/model/productItem';
import { Product } from '@src/model/product';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-wizard-find-serializable-product',
    templateUrl: './wizard-find-serializable-product.html'
})
export class WizardFindSerializableProductComponent {
    @Input() selectedProductItem: ProductItem;
    @Input() canSelectFakeSN = false;
    @Input() showWarningMessage = false;
    @Input() canDoDuplication = true;
    @Input() productItem = new ProductItem();
    @Input() ExcludeCurrentProductItem = false;
    @Output() onselectProductItem = new EventEmitter<ProductItem>();
    @Output() onselectProduct = new EventEmitter<ProductItem>();

    productItems: ProductItem[];
    products: Product[];
    showFakeWizard = false;
    seriaNumber = '';
    sku: string;
    canSearchBySku = true;
    showProductSold = false;
    showLimitedEditionHint = false;

    constructor(private productsService: ProductsService, private translateService: TranslateService) {
        this.showLimitedEditionHint = environment.clientName == "frederique" || environment.clientName == "alpina";
    }

    getProductItemsBySN(): void {
        this.clean();
        this.productsService.getProductItemsBySN(this.seriaNumber)
            .subscribe(productsItems => {
                if (productsItems.length > 0) {
                    this.showWarningMessage = false;
                }
                if (productsItems.length > 0 && productsItems.find(pi => pi.isUsed === true) != null && !this.canDoDuplication) {
                    this.showProductSold = true;
                    this.productItems = productsItems.filter(pi => pi.isUsed !== true);
                } else {
                    this.productItems = productsItems;
                }
                if (this.ExcludeCurrentProductItem) {
                    this.productItems = productsItems.filter(pi => pi.id != this.productItem.id)
                }
            });
    }
    getProductItemsBySNKeyDonw(event) {
        if (event.keyCode === 13) {
            this.getProductItemsBySN();
        }
    }
    getProductBySku(sku: string): void {
        if (sku.length < 3) {
            return;
        }
        this.sku = sku;
        this.productsService.getProductBySKU(sku, true)
            .subscribe(
                products => this.products = products
            );
    }
    getProductBySkuKeyDonw(event, sku: string): void {
        if (event.keyCode === 13 && sku.length >= 3) {
            this.getProductBySku(sku);
        }
    }
    selectProductItem(productItem: ProductItem): void {
        this.onselectProductItem.emit(productItem);
    }
    selectProduct(product: Product): void {
        const productItem = new ProductItem();
        productItem.serialNumber = this.seriaNumber;
        productItem.product = product;
        this.onselectProduct.emit(productItem);
    }

    searchAgain(): void {
        this.reset();
    }
    continueWithFake(): void {
        this.showWarningMessage = false;
        if (this.canSelectFakeSN) {
            if (!this.canSearchBySku) {
                this.showFakeWizard = false;
            } else {
                this.showFakeWizard = true;
            }
        }
    }

    reset(): void {
        this.clean();
        this.seriaNumber = '';
    }

    private clean(): void {
        this.showFakeWizard = false;
        this.productItems = undefined;
        this.products = undefined;
        this.showProductSold = false;
    }

    handleResponseMessage(): string {
        if (this.canSelectFakeSN) {
            return this.translateService.instant('Components.ActivationSteps.product-serialnumber-not-found-fake');
        } else if (this.showProductSold) {
            return this.translateService.instant('Components.ActivationSteps.watch-already-sold');
        } else {
            return this.translateService.instant('Components.ActivationSteps.product-serialnumber-not-found');
        }
    }
}
