import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppInsightsModule } from 'microsoft-applicationinsights-angular5';
import { EnvironmentNames } from '@src/environments/environments.name';


import { AppBaseModule } from '@src/base-components/app-base.module';
import { FrederiqueRoutingModule } from './frederique-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { HttpClient } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MultiTranslateHttpLoader } from '@src/core/MultiTranslateHttpLoader';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@src/environments/environment';

import { PageLayoutComponent } from '@src/base-components/pagelayout/pagelayout.component';
import { FrederiqueComponent } from './frederique.component';
import { LoginComponent } from '@src/auth/login.component';
import { PageHeaderComponent } from '@src/shared/page-header/page-header.component';
import { DashboardComponents } from '@src/base-components/dashboard/dashboard.components-container';
import { ActivationComponents } from '@src/base-components/activation/activation.components-container';
import { ActivationComponent } from './activation/activation.component';
import { SaleDetailsCorrectionComponent } from './sales/sale-details/product/sale-details-correction.component';
import { RetailerDetailsInfoFrederiqueComponent } from './retailers/retailer-details/retailer-details-info/retailer-details-info.component';
import { SalesComponents } from '@src/base-components/sales/sales.components-container';
import { RetailersComponents } from '@src/base-components/retailers/retailers.components-container';
import { SidebarFrederiqueComponent } from './sidebar/sidebarFrederique.component';
import { ProfileComponent } from '@src/base-components/profile/profile.component';
import { ToastrModule } from 'ngx-toastr';
import { ToastCustomOptions } from './extra/toastr.custom-option';
import { PageNotFoundComponent } from '@src/shared/page-notfound.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SaleDetailsProductFrederiqueComponent } from './sales/sale-details/product/sale-details-product.component';

import { FindUserByUsernameComponent } from '@src/base-components/users/find-user/find-user-username.component';
import { UserAccessRoleRules } from '@src/auth/user-access-roles-rules';
import { FrederiqueAccessRules } from './frederique-access-rules';
import { AppSettings } from '@src/core/AppSettings';
import { PrivacyModalContentComponent } from '@src/base-components/privacy/privacy-modal-content.component';
import { ManagementComponents } from '@src/base-components/management/management.container';
import { UsersComponents } from '@src/base-components/users/users.container';
import { RepairsComponents } from '@src/base-components/repairs/repairs.container';
import { CustomersComponents } from '@src/base-components/customers/customers.components-container';
import { InventoriesComponents } from '@src/base-components/inventories/inventories.components-container';

import { ExternalSearchFrederiqueComponent } from './external-search/external-search-frederique.component';
import { SearchSaleDetailsComponent } from '@src/base-components/external-search/sale-details/sale-details.component';

import { FileUploadModule } from 'ng2-file-upload';
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_BASE_URL } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { AssistanceComponent } from './assistance/assistance.component';
import { TermsComponent } from './terms/terms.component';
import { ExternalSearchComponent } from '@src/base-components/external-search/external-search.component';
import { HelpComponent } from './help/help.component';
import { SaleDetailsWarrantiesFrederiqueComponent } from './sales/sale-details/warranties/sale-details-warranties.component';
import { QRCodePrintingComponents } from '@src/base-components/qr-code-printing/qrcodeprinting.components-container';
import { InventoryItemAddSerialNumberComponent } from '@src/base-components/inventories/inventory-details/items-table/items-add-serial-number/items-add-serial-number';
import { InventoryItemDeleteSerialNumberComponent } from '@src/base-components/inventories/inventory-details/items-table/items-delete-serial-number/items-delete-serial-number';
import { FrederiqueAppSettings } from './FrederiqueAppSettings';
import { ProductsComponents } from '@src/base-components/products/products.components-container';
import { WizardCreateSerialNumberComponent } from '@src/base-components/wizard/create-searial-number/wizard-create-serial-number.component';
import { SalesFilterComponent } from '@src/base-components/sales/filter/sales-filter.component';
import { ShipmentsComponents } from '@src/base-components/shipments/shipments.components-container';

export function translateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: '/locale/',
            suffix: '.json'
        }, {
            prefix: '/locale/custom/privacy.',
            suffix: '.json'
        }, {
            prefix: '/locale/custom/',
            suffix: '.json'
        }
    ]);
}


let instrumentationKey = '';
if (environment.envName === EnvironmentNames.prod) {
    instrumentationKey = '1445a3f3-dc0c-421d-aabd-040dcb4272d7'; //demo
} else {
    instrumentationKey = '980f2ac3-0a71-4d60-85a7-cc13237cfa84'; //dev
}

@NgModule({
    imports: [
        AppBaseModule.forRoot(),
        AppInsightsModule.forRoot(instrumentationKey),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translateLoader),
                deps: [HttpClient]
            }
        }),
        FrederiqueRoutingModule,
        NgxDatatableModule,
        NgxChartsModule,
        ToastrModule.forRoot(ToastCustomOptions),
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    declarations: [
        PageLayoutComponent,
        PrivacyModalContentComponent,
        PageHeaderComponent,
        FrederiqueComponent,
        PageNotFoundComponent,
        PrivacyComponent,
        TermsComponent,
        AssistanceComponent,
        ActivationComponent,
        LoginComponent,
        ProfileComponent,
        FindUserByUsernameComponent,
        InventoryItemAddSerialNumberComponent,
        InventoryItemDeleteSerialNumberComponent,
        SaleDetailsCorrectionComponent,
        RetailerDetailsInfoFrederiqueComponent,
        SidebarFrederiqueComponent,
        SearchSaleDetailsComponent,
        ExternalSearchComponent,
        ExternalSearchFrederiqueComponent,
        SaleDetailsProductFrederiqueComponent,
        SaleDetailsWarrantiesFrederiqueComponent,
        HelpComponent,
        WizardCreateSerialNumberComponent,
        ...UsersComponents,
        ...ManagementComponents,
        ...ActivationComponents,
        ...SalesComponents,
        ...RetailersComponents,
        ...QRCodePrintingComponents,
        ...DashboardComponents,
        ...RepairsComponents,
        ...CustomersComponents,
        ...InventoriesComponents,
        ...ProductsComponents,
        ...ShipmentsComponents
    ],
    bootstrap: [FrederiqueComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: AuthConfig, useValue: environment.authConfig },
        { provide: UserAccessRoleRules, useClass: FrederiqueAccessRules },
        { provide: 'AuthService', useClass: environment.authServiceType },
        { provide: AppSettings, useClass: FrederiqueAppSettings },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.siteKey,
            } as RecaptchaSettings,
        },
        {
            provide: RECAPTCHA_BASE_URL,
            useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
        }
    ],
    entryComponents: [PrivacyModalContentComponent, SalesFilterComponent]
})
export class FrederiqueModule { }
