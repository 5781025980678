import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { SharedDataService } from '@src/services/sharedData.service';
import { Observable } from 'rxjs';
import { RetailersService } from '@src/services/retailers/retailers.service';
import { IAuthService } from '@src/auth/auth.service.interface';
import { UserProfile } from '@src/auth/user-profile';
import { ConfigService } from '@src/services/layout/config.service';
import { TranslateService } from '@ngx-translate/core';
import { RetailersParameters } from '@src/model/filter-parameters/retailers-parameters';
import { UserNotification } from '@src/model/notification';
import { UsersService } from '@src/services/users/users.service';
import { NotificationsService } from '@src/services/notifications/notifications.service';

@Component({
  selector: 'app-retailers-filter',
  templateUrl: './retailers-filter.component.html',
  styleUrls: ['./retailers-filter.component.scss']
})

export class RetailersFilterComponent implements OnInit {
  inputNameCodeQuery: string;
  inputCityQuery: string;
  inputMarketOrBusinessQuery: string;
  loggedUser: UserProfile;
  layoutDirection: string;
  filterParameters: RetailersParameters;
  checkedActive = false;
  checkedInactive = false;
  isLoading = false;
  isOpen = true;
  @Output() changeFilter = new EventEmitter<RetailersParameters>();
  @Output() clearFilter = new EventEmitter<RetailersParameters>();
  @Input() newfilterParameters : RetailersParameters;
  constructor(
    @Inject('AuthService') private authService: IAuthService,
    private retailersService: RetailersService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private userService: UsersService,
    private notifcationService : NotificationsService,
    private sharedDataService : SharedDataService) {
    this.layoutDirection = this.configService.getLayoutOrientation();
    this.authService.getCurrentUser().subscribe(u => {
      this.loggedUser = u;
    });
  }
  ngOnInit() {
    this.filterParameters = new RetailersParameters();
    if(this.newfilterParameters !== undefined)
    {
      this.filterParameters = this.newfilterParameters;
      if(this.newfilterParameters.active)
      {
        this.checkedActive = true;
      }
      if(this.newfilterParameters.inactive)
      {
        this.checkedInactive = true;
      }           
      this.inputNameCodeQuery = this.newfilterParameters.name;
      this.inputCityQuery = this.newfilterParameters.city;
      
    }
    this.changeFilter.emit(this.filterParameters);
  }

  clear() {
    this.inputNameCodeQuery = '';
    this.inputCityQuery = '';
    this.inputMarketOrBusinessQuery = '';
    this.checkedInactive = false;
    this.checkedActive = false;

    this.filterParameters = new RetailersParameters();
    this.clearFilter.emit(this.filterParameters);
  }

  changeMarket(target: any) {
    const entryId = target.item.id;
    const entryType = target.item.type;
    this.inputMarketOrBusinessQuery = target.item.name;
    if(entryType === 'm'){
    this.filterParameters.marketId = entryId;
    }
    else {
    this.filterParameters.businessGroup = this.inputMarketOrBusinessQuery;
    }
    this.changeFilter.emit(this.filterParameters);
  }

  changeCity(target: any) {
    this.inputCityQuery = target.item.name;

    this.filterParameters.city = this.inputCityQuery;

    this.changeFilter.emit(this.filterParameters);
  }

  changeNameCode(target: any) {
    const entryType = target.item.type;
    this.inputNameCodeQuery = target.item.name;
    this.filterParameters.name = this.inputNameCodeQuery.split(')')[1].trim();
    this.filterParameters.code = this.inputNameCodeQuery.match(/\(([^)]+)\)/)[1];
    this.changeFilter.emit(this.filterParameters);
  }


  formatter = (x: { name: string }) => x.name;
  formatterNameCode = (x: { name: string }) =>{ 
    if(this.inputNameCodeQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputNameCodeQuery};
    }
    return x.name;
  };
  formatterCity = (x: { name: string }) =>{ 
    if(this.inputCityQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputCityQuery};
    }
    return x.name;
  };
  formatterMarketOrBusiness = (x: { name: string }) =>{ 
    if(this.inputMarketOrBusinessQuery == ""){
      x = {name: undefined};
    }else{
      x = {name:this.inputMarketOrBusinessQuery};
    }
    return x.name;
  };
  searchMarketEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'marketname', 'business')
      )

  searchNameOrCodeEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'name', 'code')
      )

  searchCityEntry = (text$: Observable<string>) =>
    text$.debounceTime(200)
      .distinctUntilChanged()
      .switchMap(term =>
        this.retailersService.getRetailersSuggestions(term, 'city')
      )

  filterActive() {
    this.filterParameters.active = this.checkedActive;
    this.changeFilter.emit(this.filterParameters);
  }

  filterInactive() {
    this.filterParameters.inactive = this.checkedInactive;
    this.changeFilter.emit(this.filterParameters);
  }

  exportRetailers(event) {
    if (event.detail > 1) {
      return;
    }
    this.isLoading = true;
    this.isOpen = true;
    const userNotification = new UserNotification();
    userNotification.descriptionType = 2;
    userNotification.isDeleted = false;
    userNotification.isRead = false;
    userNotification.notificationType = 2;
    userNotification.sourcePageType=3;
    this.userService.getMyUser().subscribe(data => {
      userNotification.userId = data.id;
      this.notifcationService.createNotification(userNotification).subscribe(data => {
        this.filterParameters.parentNotificationId=data.id;
        this.retailersService.exportRetailers(this.filterParameters).subscribe(data => {
          this.isLoading = false;
          this.isOpen = false;
        });
        setTimeout(() => {
          this.sharedDataService.changeMessage("notification:created");
        }, 60000);
      });
    })
  }
  
}
